.app-logs-item {
  --min-height : 25px;
  font-size: 0.8rem;
}

.app-logs-item ion-label {
  margin-top: 3px;
  margin-bottom: 2px;
}

.item-native {
  padding-left: 6px;
}