:root {
  --app-color-bg-neutral: #fafafa;
  --app-color-bg-highlight: #e0e0e070;
  --app-color-text: #353e4b;

  --app-color-border-neutral: #dedede;

  --ion-color-main: #1f5377;
  --ion-color-main-rgb: 31,83,119;
  --ion-color-main-contrast: #ffffff;
  --ion-color-main-contrast-rgb: 255,255,255;
  --ion-color-main-shade: #1b4969;
  --ion-color-main-tint: #356485;
  --ion-background-color: var(--app-color-bg-neutral);

  --ion-color-gray: #7d7d7d;
  --ion-color-gray-rgb: 125,125,125;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 255,255,255;
  --ion-color-gray-shade: #6e6e6e;
  --ion-color-gray-tint: #8a8a8a;
}

ion-content {
  --background: var(--app-color-bg-neutral);
}

ion-header {
  background: var(--ion-color-main);
  --ion-toolbar-background: var(--ion-color-main-rgb);
  color: #ffffff;
  --ion-toolbar-color: #ffffff;
}

@media only screen and (min-width: 768px) {
  ion-modal {
    --max-width: 1000px;
    --width: 90%;
    --height: 90%;
  }
}

/* centering and limiting the width of all the content on desktop. IonHeader and IonContent elements get
   app-layout class */
.app-layout {
  max-width: 1170px;
}
.ion-page {
  align-items: center !important;
}
ion-modal .ion-page {
  align-items: normal !important;
}

.ion-color-main {
  --ion-color-base: var(--ion-color-main);
  --ion-color-base-rgb: var(--ion-color-main-rgb);
  --ion-color-contrast: var(--ion-color-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-shade);
  --ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.app-cursor-pointer {
  cursor: pointer;
}

.app-display-flex {
  display: flex;
}

.app-bg-neutral {
  background-color: var(--app-color-bg-neutral);
}

.app-bg-highlight {
  background-color: var(--app-color-bg-highlight);
}

.app-bg-white {
  background-color: #ffffff;
}

.app-shadow-top {
  box-shadow: 0 -4px 5px -6px rgba(0,0,0,0.5)
}

.app-header ion-title {
  font-size: 26px;
  line-height: 26px;
  color: white;
  text-shadow: 1px 1px 4px #313131;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

ion-title {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

.app-main-title-long {
  display: none;
}

.app-header-logo {
  display: flex;
}

.app-header-logo img {
  height: 15px;
  margin-top: 16px;
  margin-bottom: 0px;
}

@media only screen and (min-width: 359px) {
  .app-main-title-long { display: inline; }
  .app-header ion-title { 
    font-size: 30px; 
    line-height: 30px;
  }
}

@media only screen and (min-width: 400px) {
  .app-header ion-title { 
    font-size: 36px;
    line-height: 55px;
  }
  .app-header-logo img {
    height: 25px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
}


.app-header-button {
  position: absolute;
  top: var(--ion-padding);
  right: 0;
  --background: none;
}

.app-header-button ion-button {
  --color: #black;
  --ion-toolbar-color: transparent;
  --border-width: 0;
  --box-shadow: none;
}

.app-prefix-input {
  width: 150px;
  margin-right: 15px;
  border-bottom: 1px solid var(--ion-color-gray-shade);
}

.app-no-device-text {
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
}

.app-device {
  border: 1px solid var(--app-color-border-neutral);
  border-radius: 15px;
  --inner-padding-end: 0;
  --ripple-color: transparent;
  position: relative;
}

.app-device-name {
  font-size: 22px;
  font-weight: bold;
  line-height: 40px;
}

.app-device-name-sub {
  font-size: 12px;
  line-height: 20px;
}

.app-device-details {
  box-shadow: inset 0px 4px 5px -5px rgba(0,0,0,0.5)
}

.app-device-detail-icon {
  font-size: 16px;
}

.app-device-detail-name {
  font-size: 12px;
}
.app-device-detail-latest-value-name {
  font-size: 16px;
}

.app-device-detail-value {
  font-size: 20px;
}
.app-device-detail-latest-value {
  font-size: 24px;
}

.app-device-detail-unit {
  font-size: 18px;
  font-weight: lighter;
}

.app-device-connecting {
}
.app-device-connecting-overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  opacity: 0.6;
}
.app-device-connecting-spinner-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.app-device-connecting-spinner {
  --color: var(--ion-color-main);
}
.app-device-action-button {
  margin: 0;
}

/*-------------------------*/
.app-settings-modal {
  --max-height: 90%;
  --min-height: 60%;
  --width: 90%;
  --border-width: 2px;
  --border-color: #000;
}

.app-settings-modal .modal-wrapper {
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.5)
}

.app-setting-item {
  font-size: 20px;
}

.app-setting-name {
  font-weight: bold;
}

.app-setting-item-unit {
  font-weight: lighter;
  font-size: 18px;
}

.app-settings-modal-unit {
  font-weight: lighter;
  font-size: 18px;
  padding-left: 18px;
  padding-right: 18px;
}

.app-settings-modal .label-text-wrapper {
  font-size: 28px;
  margin-bottom: 15px;
}

.app-settings-modal .helper-text {
  font-size: 18px;
}

.app-settings-modal ion-item {
  --inner-border-width: 0;
}

.app-settings-modal ion-toggle {
  font-size: 18px;
}

.app-settings-modal ion-input {
  font-size: 24px;
  --padding-bottom: 5px;
}

.app-error-text {
  padding: 16px;
  color: var(--ion-color-danger);
}

.app-settings-popover {
  /*--offset-x: -100px !important;*/
  /*--height: 48px;*/
}

/*-------------------------*/

.app-module-values-table {
  border: 1px solid var(--app-color-border-neutral);
}

.app-module-values-table-header {
  font-size: 16px;
  font-weight: bold;
}

.app-module-values-table ion-row {
  border-bottom: 1px solid #00000010;
}

.app-module-values-table ion-row:first-child {
  text-align: center;
  border-bottom: 1px solid black;
}

.app-module-values-table ion-row:not(:first-child) ion-col {
  text-align: right;
  border-left: 1px solid #00000010;
}

.app-module-values-table ion-row ion-col:first-child {
  text-align: center;
}

.app-module-values-table-values {
  font-size: 16px;
}

.app-module-values-table-values.is-selected {
  background: var(--app-color-bg-highlight);
}


.app-module-values-table-values .left-column {
  color: #8884d8;
}

.app-module-values-table-values .right-column {
  color: #e03838;
}

/*-------------------------*/

.app-chart-axis-controls ion-button {
  font-weight: bold;
  font-size: 15px;
}

/*-------------------------*/
/*---------- OTA ----------*/
/*-------------------------*/
.app-ota-pin-warning {
  padding-left: 26px;
  padding-right: 26px;
  color: var(--ion-color-danger);
}

.app-ota-file-wrapper {
  width: 100%;
  padding: 10px 0;
}

.app-ota-file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  margin: 10px 0 20px 0;
  width: 100%;
}

.app-ota-file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}

.app-ota-file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: .075rem solid #ddd;
  border-radius: .25rem;
  box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.app-ota-file-custom::before {
  position: absolute;
  top: -.075rem;
  right: -.075rem;
  bottom: -.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: .075rem solid #ddd;
  border-radius: 0 .25rem .25rem 0;
}

.app-ota-file-custom::after {
  content: "Choose a .gbl file...";
}

.app-ota-item {
  padding-left: 10px;
  padding-right: 10px;
}

.app-ota-item .label-text-wrapper {
  font-size: 24px !important;
  margin-bottom: 15px;
}

.app-ota-item .helper-text {
  font-size: 16px;
}

.app-ota-item ion-input {
  font-size: 20px;
  --padding-bottom: 5px;
}

ion-item.app-ota-item  {
  --inner-border-width: 0;
}

#app-ota .app-error-text {
  padding-left: 26px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 42px;
}

/* ------------------- */

.app-ota-file-empty {
  font-style: italic;
  margin-left: 5px;
}
.app-ota-file-selected {
  font-size: 18px;
}

.app-ota-upload-button-wrapper {
  text-align: center;
}

.app-ota-upload-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.app-ota-progress {
  font-size: 18px;
}
