
.app-module-card {
  display: flex;
  flex-direction: column;
  margin: 5px;
  height: 180px;
  --ion-margin: 10px;

  -webkit-box-shadow: 0 4px 8px rgba(0,0,0,.12);
  box-shadow: 0 4px 8px rgba(0,0,0,.12);

  --ripple-color: transparent;

  border-radius: 15px;
  border: 1px solid rgb(var(--ion-color-main-rgb), 0.3);

  --background: var(--ion-color-main-contrast);
}

.app-module-card.active {
  background: var(--app-color-bg-highlight);
}

.app-module-card-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  border-radius: 0 0 0 15px;
  text-align: left;
}

.app-module-card-unit {
  /*font-weight: lighter;*/
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}


.app-module-card-title {
  font-size: 24px;
}

.app-module-card-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px 4px 8px 12px;
  border-top: 1px solid var(--app-color-border-neutral);
  color: black;
  width: 100%;
  text-align: left;
  border-radius: 15px 15px 0 0;
  font-size: 18px;
  font-weight: bold;
}

