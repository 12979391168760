.app-uart-logs-item {
  font-size: 14px;
}

.app-uart-logs-item ion-label {
  margin-top: 3px;
  margin-bottom: 2px;
}

.app-uart-msg {
  word-break: break-word;
}